const postMarkup = data => {
  const {
    title,
    category,
    image_url,
    alt_text,
    permalink
  } = data;
  let markup = `
        <div class="post-card">
          <div class="post-card__image">
            <a href="${permalink}"><img src="${image_url}" alt="${alt_text}" /></a>
          </div>
          <div class="post-card__text">
            <div>
              <h4>${category}</h4>
              <a href="${permalink}"><h3 class="main-subtitle">${title}</h3></a>
            </div>
            <div class="flex-layout justify-end">
              <a href="${permalink}" class="yellow-link">${CURRENT_LANG == "es" ? "Aprende más" : "Learn More"}</a>
            </div>
          </div>
        </div>
  `;
  return markup;
};
const groupMarkup = data => {
  const {
    cat_name,
    cat_posts
  } = data;
  let markup = `
        <div class="posts-group">
          <h2>${cat_name}</h2>
          <div class="posts-group__items">`;
  for (let index in cat_posts) {
    post = cat_posts[index];
    markup += postMarkup(post);
  }
  markup += `</div>
        </div>
  `;
  return markup;
};
const posts_container = document.getElementById("posts-container");
const posts_loading = document.getElementsByClassName("posts__loading")[0];
function loadPosts(category = null) {
  jQuery.ajax({
    url: oaks_vars.ajaxurl,
    type: "post",
    data: {
      action: "oaks_ajax_getPosts",
      category: category
    },
    dataType: "json",
    beforeSend: function () {
      posts_container.innerHTML = "";
      posts_loading.style.display = "block";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        data.forEach(key => {
          posts_container.innerHTML += groupMarkup(key);
        });
      } else {
        posts_container.innerHTML = `<p class="text-center" style="width: 100%">No events to show</p>`;
      }
      posts_loading.style.display = "none";
    },
    error: function () {
      posts_loading.innerHTML = `<p class="text-center" style="width: 100%">There was an error loading the posts</p>`;
    }
  });
}
if (posts_container) {
  loadPosts();
}
const blog_filters = document.querySelectorAll(".blog__filters li");
if (blog_filters) {
  for (let l = 0; l < blog_filters.length; l++) {
    blog_filters[l].addEventListener("click", () => {
      for (let m = 0; m < blog_filters.length; m++) {
        blog_filters[m].classList.remove("active");
      }
      blog_filters[l].classList.add("active");
      const cat = blog_filters[l].dataset.cat;
      console.log(cat);
      if (cat === "all") {
        loadPosts();
        document.querySelector(".featured-post").style.display = "block";
      } else {
        loadPosts(cat);
        document.querySelector(".featured-post").style.display = "none";
      }
    });
  }
}