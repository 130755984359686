const eventMarkup = data => {
  const {
    title,
    permalink,
    date,
    start_hour,
    end_hour,
    image_url,
    alt_text
  } = data;
  let markup = `
        <div class="event-card flex-layout">
          <div class="event-card__image">
            <img src="${image_url}" alt="${alt_text}" />
          </div>
          <div class="event-card__text">
            <h3>${title}</h3>
            <div class="event-card__date">${date}</div>
            <div class="event-card__hours">${start_hour} ${end_hour}</div>
            <a href="${permalink}" class="event-card__link yellow-link">${CURRENT_LANG === "es" ? "Ver Evento" : "View event"}</a>
          </div>
        </div>
  `;
  return markup;
};
const monthString = month => {
  let str;
  if (month < 9) {
    str = `0${month + 1}`;
  } else {
    str = month + 1;
  }
  return str;
};
let months_array;
if (CURRENT_LANG == "es") {
  months_array = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
} else {
  months_array = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}
let currentDate = new Date();
let currentMonth = currentDate.getMonth();
let currentYear = currentDate.getFullYear();
const events_container = document.getElementsByClassName("events-container");
const loading_container = document.getElementsByClassName("events__loading");
function loadEvents(selector, location, month = monthString(currentMonth), year = currentYear, loading_selector = loading_container[0]) {
  jQuery.ajax({
    url: oaks_vars.ajaxurl,
    type: "post",
    data: {
      action: "oaks_ajax_getEvents",
      location: location,
      month: month,
      year: year
    },
    dataType: "json",
    beforeSend: function () {
      selector.innerHTML = "";
      loading_selector.style.display = "block";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        data.forEach(key => {
          selector.innerHTML += eventMarkup(key);
        });
      } else {
        selector.innerHTML = `<p class="text-center" style="width: 100%">No events to show</p>`;
      }
      loading_selector.style.display = "none";
    },
    error: function () {
      loading_selector.innerHTML = `<p class="text-center" style="width: 100%">There was an error loading the posts</p>`;
    }
  });
}
if (events_container) {
  for (let e = 0; e < events_container.length; e++) {
    let eventDate = new Date();
    let eventMonth = eventDate.getMonth();
    const eventLocation = events_container[e].dataset.location;
    loadEvents(events_container[e], eventLocation, monthString(currentMonth), currentYear, loading_container[e]);
    document.querySelectorAll(".current-month")[e].innerHTML = `${months_array[eventDate.getMonth()]} ${eventDate.getFullYear()}`;
    const nextMonth = document.querySelectorAll(".events-month__next");
    const prevMonth = document.querySelectorAll(".events-month__prev");
    nextMonth[e].addEventListener("click", () => {
      const newDate = new Date(eventDate.setMonth(eventDate.getMonth() + 1));
      eventDate = newDate;
      eventMonth = eventDate.getMonth();
      eventYear = eventDate.getFullYear();
      console.log(monthString(eventMonth));
      loadEvents(events_container[e], eventLocation, monthString(eventMonth), eventYear, loading_container[e]);
      document.querySelectorAll(".current-month")[e].innerHTML = `${months_array[eventDate.getMonth()]} ${eventDate.getFullYear()}`;
      if (eventMonth > currentMonth) {
        prevMonth[e].removeAttribute("disabled");
      }
    });
    prevMonth[e].addEventListener("click", () => {
      const newDate = new Date(eventDate.setMonth(eventDate.getMonth() - 1));
      eventDate = newDate;
      eventMonth = eventDate.getMonth();
      eventYear = eventDate.getFullYear();
      console.log(monthString(eventMonth));
      loadEvents(events_container[e], eventLocation, monthString(eventMonth), eventYear, loading_container[e]);
      document.querySelectorAll(".current-month")[e].innerHTML = `${months_array[eventDate.getMonth()]} ${eventDate.getFullYear()}`;
      if (eventMonth <= currentMonth && eventYear <= currentYear) {
        prevMonth[e].setAttribute("disabled", "true");
      }
    });
  }
}