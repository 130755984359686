const providerMarkup = data => {
  const {
    title,
    speciality,
    image_url,
    alt_text,
    degree,
    clinic,
    clinic_link,
    languages
  } = data;
  let markup = `
        <div class="provider-card">
          <div class="provider-card__image">
            <img src="${image_url}" alt="${alt_text}" />
          </div>
          <div class="provider-card__text">
            <h4>${speciality}</h4>
            <h3 class="main-subtitle">${title}</h3>
            ${degree != "" ? `<div class="provider-card__degree">${degree}</div>` : ""}
            <div class="provider-card__degree">${languages}</div>
            <a href="${clinic_link}" class="provider-card__clinic">${clinic}</a>
          </div>
        </div>
  `;
  return markup;
};
const providers_container = document.getElementById("providers-container");
const providers_loading = document.getElementsByClassName("providers__loading")[0];
let current_order = "ASC";
let current_language = null;
let current_clinic = null;
function loadProviders(language = current_language, clinic = current_clinic, order = current_order) {
  jQuery.ajax({
    url: oaks_vars.ajaxurl,
    type: "post",
    data: {
      action: "oaks_ajax_getProviders",
      language: language,
      clinic: clinic,
      order: order
    },
    dataType: "json",
    beforeSend: function () {
      providers_container.innerHTML = "";
      providers_loading.style.display = "block";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        data.forEach(key => {
          providers_container.innerHTML += providerMarkup(key);
        });
      } else {
        providers_container.innerHTML = `<p class="text-center" style="width: 100%">No providers to show</p>`;
      }
      providers_loading.style.display = "none";
    },
    error: function () {
      providers_loading.innerHTML = `<p class="text-center" style="width: 100%">There was an error loading the posts</p>`;
    }
  });
}
if (providers_container) {
  loadProviders();
}
const specialities_select = document.getElementById("specialities-select");
if (specialities_select) {
  specialities_select.addEventListener("change", () => {
    console.log(specialities_select.value);
    if (specialities_select.value == "") {
      current_language = null;
    } else {
      current_language = specialities_select.value;
    }
    loadProviders();
  });
}
const clinics_select = document.getElementById("clinics-select");
if (clinics_select) {
  clinics_select.addEventListener("change", () => {
    console.log(clinics_select.value);
    if (clinics_select.value == "") {
      current_clinic = null;
    } else {
      current_clinic = clinics_select.value;
    }
    loadProviders();
  });
}
const providers_order = document.getElementById("providers-order");
if (providers_order) {
  providers_order.addEventListener("click", () => {
    if (current_order === "ASC") {
      current_order = "DESC";
      providers_order.innerHTML = "Z/A";
    } else {
      current_order = "ASC";
      providers_order.innerHTML = "A/Z";
    }
    loadProviders();
  });
}