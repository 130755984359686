const tabsWrapper = document.querySelector(".g-map__locations-inner");
const map_loader = document.getElementById("map-loader");
const dropdownList = document.getElementById("dropdown-list");
const states_select = document.getElementById("states-select");
const map_filters = document.getElementById("map-filters");
const locations_zip = document.getElementById("locations-zip");
let infoWindows = [];
const map_pin = `${BASE_URL}/wp-content/themes/oaks-theme/assets/svg/marker.svg`;
const iconHover = `${BASE_URL}/wp-content/themes/oaks-theme/assets/svg/marker-hover.svg`;
const locationTabMarkup = data => {
  const {
    title,
    address,
    link
  } = data;
  let markup = `
          <div class="map-tab">
            <h3>${title}</h3>
            <h4>${address}</h4>
            <a class="map-tab__link" href="${link}">${CURRENT_LANG == "es" ? "Ver más" : "View More"}</a>
          </div>
    `;
  return markup;
};
const infoWindowMarkup = data => {
  const {
    title,
    address,
    link,
    phone
  } = data;
  let markup = `
          <div class="map-info">
            <h3>${title}</h3>
            <h4 class="map-info__address">${address}</h4>
            <h4 class="map-info__phone">${phone}</h4>
            <a class="map-tab__link" href="${link}">${CURRENT_LANG == "es" ? "Ver más" : "View More"}</a>
          </div>
    `;
  return markup;
};
const tabAction = (id, marker, key, MAP) => {
  const countryTab = document.querySelectorAll(".map-tab");
  let nameMarkerOnHover = infoWindowMarkup(key);
  let nameMarkerOnClick = new google.maps.InfoWindow({
    content: nameMarkerOnHover
  });
  countryTab[id].addEventListener("click", function () {
    highLightMarker(id, marker, MAP);
    infoWindows.push(nameMarkerOnClick);
    closeAllInfoWindows(map_pin);
    nameMarkerOnClick.open(MAP, marker);
    marker.setIcon(iconHover);
    for (let tab = 0; tab < countryTab.length; tab++) {
      countryTab[tab].classList.remove("active");
    }
    countryTab[id].classList.add("active");
  });
};
const countryDetailAction = key => {
  loadCountryDetail(key, backToCountries);
};
const loadCountryTabs = (data, wrapper) => {
  data.forEach(key => {
    wrapper.innerHTML += locationTabMarkup(key);
    //countryDetailAction(key)
  });
};

const loadProductTabs = (data, wrapper) => {
  product_id = 0;
  data.forEach(key => {
    wrapper.innerHTML += productTabMarkup(key);
    product_id++;
  });
};
let dataArray;
function loadLocations(zip_code, state) {
  GoogleMapsMarkers = [];
  map_loader.classList.add("active");
  jQuery.ajax({
    url: oaks_vars.ajaxurl,
    type: "post",
    data: {
      action: "oaks_ajax_getLocations",
      zip_code: zip_code,
      state: state
    },
    dataType: "json",
    beforeSend: function () {},
    success: function (data) {
      //console.log("data:", data);
      if (data && data.length > 0) {
        if (tabsWrapper) {
          tabsWrapper.innerHTML = "";
          loadCountryTabs(data, tabsWrapper);
        }
        LoadGoogleMaps(data);
      } else {
        if (tabsWrapper) {
          tabsWrapper.innerHTML = `<p class="text-center">No locations to show</p>`;
        }
        LoadGoogleMaps([]);
      }
      map_loader.classList.remove("active");
    },
    error: function () {
      console.log("error");
    }
  });
}
const filterCountryBtn = document.getElementById("filter-country");
const filterProductsBtn = document.getElementById("filter-products");
if (filterProductsBtn) {
  filterProductsBtn.addEventListener("click", () => {
    loadProductCats();
    filterCountryBtn.classList.remove("active");
    filterProductsBtn.classList.add("active");
  });
}
if (filterCountryBtn) {
  filterCountryBtn.addEventListener("click", () => {
    loadLocations();
    filterCountryBtn.classList.add("active");
    filterProductsBtn.classList.remove("active");
  });
}
const highLightMarker = (id, marker, MAP) => {
  const latLng = marker.getPosition();
  MAP.setCenter(latLng);
  MAP.setZoom(10);
};
const LOCATIONS_MAPS_WRAPPER = document.getElementById("locations-map");
const g_map = document.getElementById("g-map");
if (LOCATIONS_MAPS_WRAPPER) {
  setTimeout(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.size > 0) {
      const params = Object.fromEntries(urlSearchParams.entries());
      const state = params.state;
      if (state) {
        console.log(state);
        loadLocations("", state);
        states_select.value = state;
        MAP.setZoom(10);
      }
    } else {
      loadLocations();
    }
  }, 500);
  if (g_map) {
    g_map.scrollIntoView({
      behavior: "smooth"
    });
  }
}

//Load Google Maps
function LoadGoogleMaps(pins) {
  let MAP;
  //SET POSITION OF MAP
  let PinPosition = new google.maps.LatLng(36.148988260643996, -115.16392167797002); //set longitude and latitude
  async function InitializeMap() {
    // option for google map object
    let myOptions = {
      zoom: 10,
      center: PinPosition,
      mapTypeId: "roadmap",
      //mapTypeControl: true,
      styles: mapStyles,
      scrollwheel: false,
      backgroundColor: "#ffffff"
      //panControl: false,
      //zoomControl: true,
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.RIGHT_TOP,
      // },
    };

    // create new map make sure a DIV with id myMap exist on page
    const {
      Map
    } = await google.maps.importLibrary("maps");
    MAP = new Map(LOCATIONS_MAPS_WRAPPER, myOptions); //generate map into div id myMap

    let pin_id = 0;
    pins.forEach(key => {
      //console.log("key" + pin_id + "", key);
      let marker = loadMarker(pin_id, key, MAP);
      GoogleMapsMarkers.push(marker);
      if (tabsWrapper) {
        tabAction(pin_id, marker, key, MAP);
      }
      pin_id++;
    });
    return MAP;
  }
  InitializeMap();
}

//Close infoWindows
function closeAllInfoWindows(icon) {
  for (var i = 0; i < infoWindows.length; i++) {
    infoWindows[i].close();
  }
  GoogleMapsMarkers.forEach(marker => {
    marker.setIcon(icon);
  });
}
function loadMarker(id, key, map_selector) {
  console.log("key", key);
  let {
    lat,
    long
  } = key;

  //console.log(map_pin);

  // create new marker
  let marker = new google.maps.Marker({
    id: id,
    map: map_selector,
    title: key.name,
    icon: map_pin,
    zIndex: 1,
    position: new google.maps.LatLng(lat, long)
  });
  let nameMarkerOnHover = infoWindowMarkup(key);
  let nameMarkerOnClick = new google.maps.InfoWindow({
    content: nameMarkerOnHover
  });
  marker.addListener("click", function () {
    infoWindows.push(nameMarkerOnClick);
    closeAllInfoWindows(map_pin);
    nameMarkerOnClick.open(map_selector, marker);
    marker.setIcon(iconHover);
    highLightMarker(id, marker, map_selector);
  });

  // marker.addListener('mouseover', function () {
  //   marker.setIcon(iconHover);
  // })

  // marker.addListener('mouseout', function () {
  //   marker.setIcon(icon);
  // })

  return marker;
}
if (map_filters) {
  map_filters.addEventListener("submit", event => {
    event.preventDefault();
    loadLocations(locations_zip.value, states_select.value);
  });
  states_select.addEventListener("change", event => {
    event.preventDefault();
    loadLocations(locations_zip.value, states_select.value);
  });
}