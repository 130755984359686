(function ($) {
  //loadPosts();

  $(".main-hero__carousel-inner").slick({
    dots: true,
    fade: true
  });
  $(".testimonials__carousel").slick({
    fade: true
  });
  $(".page-menu ul").slick({
    //fade: true,
    variableWidth: true,
    infinite: false,
    slidesToShow: 6,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 460,
      settings: {
        slidesToShow: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  // $(".fancybox").fancybox({
  //     padding: 0,
  // });

  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
  setTimeout(function () {
    if ($(".rellax").size() > 0) {
      var rellax = new Rellax(".rellax");
    }
  }, 100);
})(jQuery);
const servicesCard = document.getElementsByClassName("service-card");
if (servicesCard) {
  for (let i = 0; i < servicesCard.length; i++) {
    const cardButton = servicesCard[i].querySelector(".service-card__button");
    cardButton.addEventListener("click", () => {
      if (servicesCard[i].classList.contains("active")) {
        servicesCard[i].classList.remove("active");
      } else {
        for (let s = 0; s < servicesCard.length; s++) {
          servicesCard[s].classList.remove("active");
        }
        servicesCard[i].classList.add("active");
      }
    });
  }
}
window.onbeforeunload = function () {
  let hash = window.location.hash;
  console.log(hash);
  if (hash != "") {
    window.scrollTo(0, 0);
  }
};
document.addEventListener("DOMContentLoaded", event => {
  let hash = window.location.hash;
  console.log(hash);
  if (hash != "") {
    setTimeout(() => {
      window.scrollTo(0, 100);
    }, 1);
    setTimeout(() => {
      const selector = document.querySelector(hash);
      var topPos = selector.offsetTop;
      jQuery("html,body").animate({
        scrollTop: topPos - 100
      }, 1000);
      selector.querySelector(".service-card__button").click();
    }, 600);
  }
});
const hours_map = document.getElementById("hours-map");
if (hours_map) {
  const map_lat = parseFloat(hours_map.dataset.lat);
  const map_long = hours_map.dataset.long;
  const InitializeHoursMap = () => {
    let PinPosition = new google.maps.LatLng(map_lat, map_long);
    let myOptions = {
      zoom: 11,
      center: PinPosition,
      mapTypeId: "roadmap",
      mapTypeControl: false,
      styles: mapStyles,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      backgroundColor: "#ffffff",
      panControl: false,
      zoomControl: true
      // zoomControlOptions: {
      //   position: google.maps.ControlPosition.RIGHT_TOP,
      // },
    };

    MAP = new google.maps.Map(hours_map, myOptions); //generate map into div id myMap

    new google.maps.Marker({
      map: MAP,
      title: hours_map.dataset.title,
      icon: `${BASE_URL}/wp-content/themes/oaks-theme/assets/svg/pin.svg`,
      zIndex: 1,
      position: new google.maps.LatLng(map_lat, map_long)
    });
    return MAP;
  };
  InitializeHoursMap();
}
const share_links = document.querySelectorAll(".share-links a");
if (share_links) {
  for (let s = 0; s < share_links.length; s++) {
    share_links[s].addEventListener("click", event => {
      if (share_links[s].classList.contains("popup-share")) {
        event.preventDefault();
        const thisHref = share_links[s].href;
        window.open(thisHref, "newwindow", "width=460, height=500, top=100, left=100");
      }
    });
  }
}
const open_menu = document.getElementById("open-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.add("mobile-open");
  });
}
const close_menu = document.getElementById("close-menu");
if (close_menu) {
  close_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.remove("mobile-open");
  });
}
const page_menu_items = document.querySelectorAll(".page-menu a");
if (page_menu_items) {
  for (let p = 0; p < page_menu_items.length; p++) {
    page_menu_items[p].addEventListener("click", event => {
      event.preventDefault();
      const elHref = page_menu_items[p].getAttribute("href");
      jQuery("html, body").animate({
        scrollTop: jQuery(`${elHref}`).offset().top - 140
      }, 1000);
    });
  }
  window.addEventListener("scroll", function (e) {
    var h = window.innerHeight;
    for (let p = 0; p < page_menu_items.length; p++) {
      const elHref = page_menu_items[p].getAttribute("href").substring(1);
      const hrefElement = document.getElementById(elHref);
      var viewportOffset = hrefElement.getBoundingClientRect();
      const elementHeight = hrefElement.offsetHeight;
      var topPos = viewportOffset.top;
      if (topPos <= 141 && topPos > 0 - (elementHeight - 141)) {
        page_menu_items[p].classList.add("active");
        jQuery(".page-menu ul").slick("slickGoTo", p);
      } else {
        page_menu_items[p].classList.remove("active");
      }
    }
  });
}
const job_opening_select = document.getElementById("job-opening");
if (job_opening_select) {
  const position_title = document.querySelector(".position-intro h1").innerHTML;
  job_opening_select.value = position_title;
}
const actualBtn = document.getElementById("add-cv");
const fileChosen = document.querySelector(".file-name");
if (actualBtn) {
  actualBtn.addEventListener("change", function () {
    fileChosen.textContent = this.files[0].name;
  });
}